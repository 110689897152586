import { useNavigate } from "react-router-dom"
import Modal from "../../../../components/Modal/Modal"
import SuccessIcon from "../../../../components/success-icon"
import { getText } from "../../../../helpers/translateHelper"
import { HOME_PAGE } from "../../../../routes/routeConstants"
import { useDispatch } from "react-redux"
import { getPhoneNumberFromLs } from "../../../../utils/commonUtils"
import { callGetAMUserProfile } from "../../../../Redux/slices/homeSlice"

interface Props {
  heading: string,
  subHeading:string
}

const SetQuestionSucessPopup = ({ heading ,subHeading}: Props) => {
  const navigate = useNavigate(),
    dispatch = useDispatch();

  function gotoHome() {
    let amUserProfilePayload = {
      data: { msisdn: getPhoneNumberFromLs() },
    };
    dispatch(callGetAMUserProfile(amUserProfilePayload));
    navigate(HOME_PAGE, { replace: true });
  }
  return (
    <Modal
      isOpen={true}
      onClose={() => { }}
    >
      <div className="sucess-popup-ctn bg-white pb-20 rounded-t-[20px] pt-30">
      <span className="h-[74px] w-[74px] bg-[#eee] flex items-center justify-center rounded-[100%] m-auto shadow-[0_0px__0px_20px_#f6f6f6]">
          <SuccessIcon />
        </span>
        <div className="heading mt-28 px-24 text-[#1F2436] font-bold text-20 text-center">
          {heading}
        </div>
        <div className="description mt-4 text-16 text-[#1F2436] text-center px-72">
          {subHeading}
        </div>
        <div className="home-cta text-white text-18 font-bold border border-[#1F2436] bg-[#1F2436] rounded py-12 text-center mt-20 mx-22" onClick={gotoHome}>
          {getText("HOME")}
        </div>
      </div>
    </Modal>
  )
}

export default SetQuestionSucessPopup;