import { configureStore } from "@reduxjs/toolkit";

import logger from "redux-logger";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";
import { loadState, saveState } from "../utils/persistStates";
import RootSaga from "./saga";
import balanceReducer from "./slices/balanceSlice";
import bundleReducer from "./slices/bundleSlice";
import configReducer from "./slices/configSlice";
import globalReducer from "./slices/globalSlice";
import homeReducer from "./slices/homeSlice";
import loginReducer from "./slices/loginSlice";
import payBillReducer from "./slices/payBillSlice";
import sendMoneyReducer from "./slices/sendMoneySlice";
import translationReducer from "./slices/translationSlice";
import featureReducer from "./slices/featureSlice";
import appSettingsReducer from "./slices/appSettingsSlice";
import amReducer from "./slices/amSlice";
import withdrawCashReducer from "./slices/withdrawCashSlice";
import paymentReducer from "./slices/paymentSlice";
import loanReducer from "./slices/loanSlice"

export const setupStore = (preloadedState: any = {}, sagaMiddleware: SagaMiddleware<object>) => {
  return configureStore({
    reducer: {
      login: loginReducer,
      balance: balanceReducer,
      config: configReducer,
      home: homeReducer,
      sendMoney: sendMoneyReducer,
      globalStore: globalReducer,
      bundleStore: bundleReducer,
      translationStore: translationReducer,
      featureStore: featureReducer,
      appSettingsStore: appSettingsReducer,
      amStore: amReducer,
      withdrawCashStore: withdrawCashReducer,
      paymentStore: paymentReducer,
      loanStore: loanReducer
    },
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      if (process.env.NODE_ENV === "production") {
        return getDefaultMiddleware({ thunk: false, serializableCheck: false })
          .concat(sagaMiddleware)
      }
      else {
        return getDefaultMiddleware({ thunk: false, serializableCheck: false })
          .concat(sagaMiddleware)
        // .concat(logger)
      }
    }
  });
}

const sagaMiddleware = createSagaMiddleware(),
  preloadedState = loadState();

export const store = configureStore({
  reducer: {
    login: loginReducer,
    balance: balanceReducer,
    config: configReducer,
    home: homeReducer,
    translationStore: translationReducer,
    sendMoney: sendMoneyReducer,
    globalStore: globalReducer,
    bundleStore: bundleReducer,
    payBillStore: payBillReducer,
    featureStore: featureReducer,
    appSettingsStore: appSettingsReducer,
    amStore: amReducer,
    withdrawCashStore: withdrawCashReducer,
    paymentStore: paymentReducer,
    loanStore: loanReducer
  },
  preloadedState,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === "production") {
      return getDefaultMiddleware({ thunk: false, serializableCheck: false })
        .concat(sagaMiddleware)
    }
    else {
      return getDefaultMiddleware({ thunk: false, serializableCheck: false })
        .concat(sagaMiddleware)
      // .concat(logger)
    }
  }
});

store.subscribe(() => {
  const login = store.getState().login,
    balance = store.getState().balance,
    config = store.getState().config,
    home = store.getState().home,
    sendMoney = store.getState().sendMoney,
    globalStore = store.getState().globalStore,
    bundleStore = store.getState().bundleStore,
    payBillStore = store.getState().payBillStore,
    translationStore = store.getState().translationStore,
    appSettingsStore = store.getState().appSettingsStore,
    featureStore = store.getState().featureStore,
    withdrawCashStore = store.getState().withdrawCashStore,
    paymentStore = store.getState().paymentStore,
    amStore = store.getState().amStore,
    loanStore = store.getState().loanStore;

  saveState({
    login,
    balance,
    config,
    home,
    sendMoney,
    globalStore,
    bundleStore,
    payBillStore,
    translationStore,
    featureStore,
    appSettingsStore,
    withdrawCashStore,
    paymentStore,
    amStore,
    loanStore
  });
});

sagaMiddleware.run(RootSaga);
export type RootState = ReturnType<typeof store.getState>;
