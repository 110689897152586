import ReactGA from "react-ga4";
import { store } from "../Redux";
import { isWebengageEventsEnabled } from "../utils/commonUtils";

const webengage = (window as any).webengage;
interface GA4Interface {
  category: string,
  action: string,
  label?: string; // optional
  value?: number; // optional, must be a number
  data?: any //custom
}

export const ga4Initialize = () => {
  let trackingId: string | undefined = process.env.REACT_APP_GA4_TRACKING_ID || store.getState().config?.launchConfig?.ga4Id,
    enableServerSideTagging = process.env.REACT_APP_ENABLE_SERVER_SIDE_TAGGING || store.getState().config?.launchConfig?.enableServerSideTagging,
    serverContainerUrl = process.env.REACT_APP_SERVER_CONTAINER_URL || store.getState().config?.launchConfig?.serverContainerUrl,
    serverSideTaggingUrl = process.env.REACT_APP_SERVER_TAGGING_URL || store.getState().config?.launchConfig?.serverSideTaggingUrl;
  if (trackingId) {
    if (enableServerSideTagging !== 'false' && serverContainerUrl && serverSideTaggingUrl) {
      ReactGA.initialize(trackingId, { gtagUrl: serverSideTaggingUrl, gtagOptions: { server_container_url: serverContainerUrl } });
    }
    else {
      ReactGA.initialize(trackingId);
    }
  }
}

export const sendGA4PageView = () => {
  ReactGA.send({ hitType: "pageview", page: window?.location?.pathname, title: window?.document?.title });
  try {
    const webengage = (window as any).webengage;
    if (isWebengageEventsEnabled() && webengage) {
      webengage.track("pageview", { page: window?.location?.pathname, title: window?.document?.title });
    }
  } catch (error) {
    console.log("Error in sending webengage event", error);
  }
}

export const sendGA4Event = ({ category, action, label, value, data }: GA4Interface) => {
  const eventInfo = {
    event_category: category || "",
    event_label: label || "", // optional
    value: value || "", // optional, must be a number
    ...data
  }
  ReactGA.event(action, eventInfo);
  try {
    const webengage = (window as any).webengage;
    if (isWebengageEventsEnabled() && webengage) {
      webengage?.track(action, { ...eventInfo, opco: process.env.REACT_APP_OPCO || "" });
    }
  } catch (error) {
    console.log("Error in sending webengage event", error)
  }
}
