export const createCheckGSMBalancePayload = (payload: any, hideLoader = false,) => {
  const { msisdn, fetchQB = true, fetchLoan = false } = payload
  return {
    data: {
      msisdn,
      lob: "",
      fetchQB,
      fetchLoan
    },
    hideLoader
  }
};