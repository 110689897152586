import { useRef, useState } from "react"
import icons from "../../config/icons";
import { useOnClickOutside } from "usehooks-ts";
import { CustomDropdownProps, IOption } from "../../config/commonInterfaces";

const CustomDropdown: React.FC<CustomDropdownProps> = ({ options, onSelect, selectedOption, selectedHeading, isDisable, optionKey, heading, label, dropDownClass, optionClass }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const icon: any = icons;

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }

  useOnClickOutside(dropdownRef, handleClickOutside)

  const handleToggleDropdown = (): void => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: IOption): void => {
    onSelect(option);
    setIsOpen(false);
  };

  const getDownIcn = () => {
    return <span className='down-icn flex justify-end'>
    <img className='w-[16px]' src={icons.downIcn} alt="" />
  </span>;
  }

  return (
    <div className={`custom-dropdown ${dropDownClass}`} ref={dropdownRef}>
      <div className={`dropdown-header flex justify-between pb-8 h-[30px] ${isDisable ? "pointer-events-none opacity-50" : ""}`} onClick={handleToggleDropdown}>
        {!!selectedOption[optionKey] ?
          <div className='selected-option h-full text-14 flex justify-between text-[#000000] items-center w-full '>
          { selectedHeading &&  !isOpen && <span className='selected-heading text-12 text-[#000000] font-[300]  capitalize absolute left-15 top-10 opacity-80'>
              {label}
            </span>}
            <span className={`operator-icn flex items-center capitalize ${selectedHeading && !isOpen?'pt-12':''}`}>
              {selectedOption[optionKey]}
            </span>
            {getDownIcn()}
          </div>
          :
          <>
            <span className='operator-icn flex items-center capitalize'>
              {heading}
            </span>
            {getDownIcn()}
          </>
        }
      </div>
      {isOpen && (
        <div className={`dropdown-options ${optionClass ? optionClass :"mt-6 border-[#DBDBDB]  border px-12 py-18 absolute left-0 right-0 w-[96%] mx-auto bg-white z-10"}`}>
          {options.map((option: any, i: number) => (
            <div
              key={i}
              className="dropdown-option flex items-center text-[#000000] text-14 mb-18 last:mb-0"
              onClick={() => handleOptionClick(option)}
            > 
              {option[optionKey]}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomDropdown;