import { createSlice } from "@reduxjs/toolkit";
import { clearStore } from "../actions";
import moment from "moment";

interface IAmSlice {
  loanProducts: any
}

const initialState: IAmSlice = {
  loanProducts: {}
}

export const loanSlice = createSlice({
  name: "loanStore",
  initialState,
  reducers: {
    callFetchLaonTransactions: (state, action) => { },
    callFetchLoanProducts: (state, action) => { },
    callFetchLoanProductsSucces: (state, action) => {
      state.loanProducts = {
        ...state.loanProducts,
        [action?.payload?.result?.vendorId]: { ...action.payload?.result }
      }
    },
    callFetchLoanProductsFailed: (state, action) => {
      state.loanProducts = {
      }
    },
    callApplyLoan: () => { }
  },
  extraReducers: (builder) => {
    builder //clear home store
      .addCase(clearStore, (state) => {
        return initialState;
      })
      .addDefaultCase((state, action) => { })
  }
});

export const {
  callFetchLaonTransactions,
  callFetchLoanProducts,
  callFetchLoanProductsFailed,
  callFetchLoanProductsSucces,
  callApplyLoan

} = loanSlice.actions;

export default loanSlice.reducer;