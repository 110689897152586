import { skeletonConfig } from "../../data/constants"

interface props {
  getskeletonHead?: boolean;
  getskeletontwoBx?: boolean;
  getskeletonCardList?: boolean;
  getskeletonFormList?: boolean
  getskeletonLogin?: boolean;
  noOfCard?: number
}

export const Skeleton = ({
  getskeletonHead,
  getskeletontwoBx,
  getskeletonCardList,
  getskeletonFormList,
  getskeletonLogin,
  noOfCard
}: props) => {
  const skeletonHead = (
    <div className=" overflow-hidden shadow  border-gray-200 divide-y divide-gray-200 rounded-[10px]  animate-pulse gray-700 md:p-6  min-h-[50px] mb-[20px] w-[100%] flex justify-between ">
      <div className="w-[47%] h-[30px] bg-gray-200   m-[10px] rounded-[5px]"></div>
      <div className="w-[47%] h-[30px] bg-gray-200   m-[10px] rounded-[5px]"></div>
    </div>
  );
  const skeletontwoBx = Array(skeletonConfig.BOX)
    .fill(true)
    .map((_, index) => (
      <div className="overflow-hidden shadow border-gray-200 divide-y divide-gray-200 rounded-[10px]  animate-pulse gray-700 md:p-6  min-h-[50px] mb-[20px] w-[47%] " key={`bx_${index}`}>
        <div className="w-[100%] h-[95px] bg-gray-200   "></div>
      </div>
    ));
  const skeletonCardList = Array(skeletonConfig.CARD_LIST)
    .fill(true)
    .map((_, index) => (
      <div className="max-w-md overflow-hidden space-y-4 border border-gray-200 divide-y divide-gray-200 rounded-[10px] shadow animate-pulse gray-700 md:p-6  min-h-[50px] mb-[20px] " key={`cardlist_${index}`}>
        <div className="flex  justify-between">
          <div className="w-[70%] p-[15px] ">
            <div className="w-[100%] h-[20px] bg-gray-300 rounded-full   mb-[10px]"></div>
            <div className="w-[80%] h-[15px] bg-gray-200 rounded-full  mb-[10px]"></div>
            <div className="w-[60%] h-[10px] bg-gray-200 rounded-full  "></div>
          </div>
          <div className="w-[90px] h-[95px] bg-gray-300   "></div>
        </div>
      </div>
    ));

  const skeletonFormList = Array(noOfCard ?? skeletonConfig.FORM_LIST)
    .fill(true)
    .map((_, index) => (
      <div className="mb-30 animate-pulse gray-700" key={`formlist_${index}`}>
        <div className="w-[100px] h-[10px] bg-gray-300 rounded-full   mb-[10px]"></div>
        <div className="w-[100%]  h-[40px] bg-gray-200   rounded-md "></div>
      </div>

    ));
  const skeletonLogin =
    <div className="mb-30 animate-pulse gray-700">
      <div className="w-[60%] h-[10px] bg-gray-200 rounded-md   mb-[20px]"></div>
      <div className="w-[100%] h-[40px] bg-gray-200 rounded-md   mb-[10px]"></div>
      <div className="w-[60%] h-[10px] bg-gray-200 rounded-md   mb-[20px]"></div>
      <div className="w-[100%] h-[40px] bg-gray-200 rounded-md   mb-[10px]"></div>
      <div className="w-[60%] h-[10px] bg-gray-200 rounded-md   mb-[20px]"></div>
      <div className="w-[100%] h-[40px] bg-gray-200 rounded-md   mb-[10px]"></div>
      <div className="w-[60%] h-[10px] bg-gray-200 rounded-md   mb-[20px]"></div>
      <div className="w-[100%] h-[40px] bg-gray-200 rounded-md   mb-[10px]"></div>
      <div className="w-[60%] h-[10px] bg-gray-200 rounded-md   mb-[20px]"></div>
      <div className="w-[100%] h-[40px] bg-gray-200 rounded-md   mb-[10px]"></div>
      <div className="w-[100%]  h-[40px] bg-gray-300   rounded-md "></div>
    </div>


  return (
    <div className="p-[15px] mt-[30px]">
      {getskeletonHead ? skeletonHead : ""}
      {getskeletontwoBx ? <div className="flex justify-between ">{skeletontwoBx}</div> : ""}
      {getskeletonCardList ? skeletonCardList : ""}
      {getskeletonFormList ? skeletonFormList : ""}
      {getskeletonLogin ? skeletonLogin : ''}

    </div>
  );
};
