import { useState, useCallback, MutableRefObject } from "react";
import { toPng, toJpeg } from "html-to-image";
import { startLoader, stopLoader } from "../helpers/commonHelpers";

export type ImgType = "jpg" | "png";

export interface UseScreenshotProps {
  /**
   * Ref to the component for which the screenshot should be taken.
   * If no ref is taken it will take the screenshot for the topmost div or body.
   */
  ref?: MutableRefObject<any>;
}

export interface UseScreenshotReturnType {
  /**
   * Indicates if the screenshot is loading this is useful for some webpages that
   * are big and have lots of content to render.
   */
  isLoading: boolean;

  /**
   * Contains the dataUrl for the image.
   */
  image: string;

  /**
   * A function to take screenshot.
   *
   * @param types: format for the image, rither jpeg or png.
   * @param options: Options to control the behaviour [OptionsType]
   */
  takeScreenshot: (types: ImgType, options: any) => void;

  /**
   * Clear the image from the memory.
   */
  clear: () => void;
}

export const useScreenshot = (options?: UseScreenshotProps) => {
  const { ref } = options || {};
  const [image, setImage] = useState<string>();
  const [isLoading, setLoading] = useState(false);

  const takeScreenshot = useCallback(
    async (type?: ImgType, options?: any) => {
      setLoading(true);
      startLoader();
      let tempImage: string | undefined;
      try {
        const body = document.getElementById("root")!;

        if (type === "jpg") {
          tempImage = await toJpeg(ref?.current || body, options);
        } else {
          tempImage = await toPng(ref?.current || body, options);
        }

        setImage(tempImage);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        stopLoader();
        return tempImage;
      }
    },
    []
  );

  const clear = useCallback(() => setImage(undefined), []);

  return { image, takeScreenshot, isLoading, clear };
};

export default useScreenshot;