import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import icons from "../../config/icons";
import { getText } from "../../helpers/translateHelper";
import { APP_SETTINGS_ROUTE, HOME_PAGE } from "../../routes/routeConstants";
import SetQuestion from "../../features/SetQuestion";
import { headings, manageQuestionHeadings } from "../../data/constants";

const SetQuestionPage = () => {
  const navigate = useNavigate();

  const locData = useLocation()?.state ?? {};

  function handleNavigation() {
    locData.from === headings.APP_SETTINGS ? navigate(APP_SETTINGS_ROUTE) : navigate(HOME_PAGE, { replace: true });
  }

  function getHeaderText() {
    return locData.from === headings.APP_SETTINGS ? getText(manageQuestionHeadings.MANAGE_SECURITY_QUESTION_HEADING) : getText(manageQuestionHeadings.SET_SECURITY_QUESTION_HEADING);
  }

  return (
    <div className="set-question-main-ctn h-[100vh]">
      <Header clickHandler={handleNavigation} text={getHeaderText()} icon={icons.backWhite} />
      <SetQuestion />
    </div>
  )
}

export default SetQuestionPage;