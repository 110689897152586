import { buyBundle, recharge } from "../routes/routeConstants"

export const eventCategory = {
    CASH_WITHDRAW: "Cash Withdraw"
}

export const eventAction = {
    CASH_OUT_VALID_AGENT_CODE: "wdrawcash_agentid_authenticate_valid",
    CASH_OUT_INVALID_AGENT_CODE: "wdrawcash_agentid_authenticate_wrong",
    VERIFY_PIN_BOTTOM_SCREEN: "verify_pin_bottom_screen",
    CONFIRMATION_SCREEN_CONFIRM_TAPPED: "wdrawcash_trxscreen_continue_clicked",
    TRANSACTION_FEES_API_INITIATED: "transaction_fees_api_initiated",
    CASH_WITHDRAWAL_AGENT_SUCCESS: "wdrawcash_trxsuccess",
    CASH_WITHDRAWAL_AGENT_FAILED: "wdrawcash_trxfailed",
    CASH_WITHDRAWAL_AGENT_INITIATED: "wdrawcash_ampin_entered",
    CASH_OUT_AGENT_SCREEN_NEXT_TAPPED: "wdrawcash_next_clicked",
    CONFIRMATION_SCREEN_CANCEL_TAPPED: "confirmation_screen_cancel_tapped",
    TRANSACTION_FAILED_INSUFFICIENT_BALANCE: "wdrawcash_trxfailed_insufficient_balance",
    TRANSACTION_FAILED_INVALID_MSISDN: "wdrawcash_trxfailed_invalid_msisdn",
    AMOUNT_ENTERED: "wdrawcash_amountentered",
    TRANSACTION_LIMIT_BREACHED: "wdrawcash_amount_trxlimit_breached"
}

export const pinMgmtEvents = {
    [buyBundle.BUNDLE_MENU]: "setpin_buy_bundle_clicked",
    [recharge.RECHARGE]: "setpin_airtime_recharge_clicked",
    "": "setpin_homescr_clicked"
}

export const securityQuestionEvents = {
    [buyBundle.BUNDLE_MENU]: "setsecurityqn_buy_bundle_clicked",
    [recharge.RECHARGE]: "setsecurityqn_airtime_recharge_clicked",
    "": "setsecurityqn_homescr_clicked"
}

export const SEND_MONEY_EVENTS = {
    SEND_MONEY: "send-money",
    SEND_MONEY_: "send_money_",
    SEND_MONEY_P2P_LOCAL_: "sndmon_p2p_local_",
    SEND_MONEY_P2P_LOCAL_OPTR_SELECTED: "sndmon_p2p_local_operatorselected",
    SEND_MONEY_P2P_LOCAL_SELECTED_OPTR: "sndmon_p2p_local_operatorselect_",
    SEND_MONEY_P2P_LOCAL_NUM_ENTERED: "sndmon_p2p_local_num_entered",
    SEND_MONEY_P2P_LOCAL_NUM_ENTERED_CRCT: "sndmon_p2p_local_num_entered_crct",
    SEND_MONEY_P2P_LOCAL_NUM_ENTERED_WRNG: "sndmon_p2p_local_num_entered_wrong",
    SEND_MONEY_P2P_LOCAL_TRX_LIMIT_BREACH: "trxlimit_breach",
    SEND_MONEY_P2P_LOCAL_TRX_SUCCESS: "trxsuccess",
    SEND_MONEY_P2P_LOCAL_TRX_FAIL: "trxfail",
    SEND_MONEY_P2P_LOCAL_TRX_FAIL_INCRT_PIN: "trxfail_incorrect_pin",
    SEND_MONEY_P2P_LOCAL_TRX_FAIL_INSUFFICIENT_BAL: "trxfail_insufficient_bal",
    SEND_MONEY_P2P_LOCAL_AM_PIN_POPUP: "send_money_am_pin_popup",
    SEND_MONEY_P2P_LOCAL_AM_PIN_ENTERED: "ampin_entered",
    SEND_MONEY_P2P_LOCAL_REASON_ENTERED: "sndmon_p2p_local_reasonentered",
    SEND_MONEY_P2P_LOCAL_AMT_ENTERED: "sndmon_p2p_local_amountentered",
    SEND_MONEY_P2P_LOCAL_NXT_CLICKED: "next_clicked",
    SEND_MONEY_P2P_LOCAL_REASON_SELECTED: "sndmon_p2p_local_reasonselected",
    SEND_MONEY_P2P_LOCAL_SELECTED_REASON: "sndmon_p2p_local_reasonselect_",
    SEND_MONEY_TXN_FEES_API_SUCCESS: "send_money_txn_fees_api_success",
    SEND_MONEY_P2P_LOCAL_REC_NAME_ENTERED: "sndmon_p2p_local_recname_entered",
    SEND_MONEY_TXN_FEES_API_FAIL: "send_money_txn_fees_api_fail",
    SEND_MONEY_TXN_DETAILS_CONTINUE_CLICK: "_txn_details_continue_click",
    SEND_MONEY_TXN_DETAILS_CLOSE_CLICK: "_txn_details_close_click",
    SEND_MONEY_NEXT_CLICKED: "_next_click",
}

export const SET_QUESTION_EVENTS = {
    SET_QUESTION: "set_question",
    SET_SECURITY_QNS_QN_SELECTED: "setsecurityqn_qnselected",
    SET_SECURITY_QN_FAIL: "setsecurityqn_fail",
    SET_SECURITY_QN_SUCCESS: "setsecurityqn_success",
    SET_SECURITY_QN_RESPONSE_ADDED: "setsecurityqn_response_added"
}

export const MANAGE_QUESTION_EVENTS = {
    MANAGE_QUESTION: "manageQuestion",
    MANAGE_SECURITY_QUESTION_SEC_QN_SCREEN_VIEWED: "managesecqn_secqnscr_viewed",
    MANAGE_SECURITY_QUESTION_QN_SELECTED: "managesecurityqn_qnselected",
    MANAGE_SECURITY_QUESTION_PIN_INPUT_DONE: "managesecurityqn_pin_input_done",
    MANAGE_SECURITY_QUESTION_PIN_INPUT_INCORRECT: "managesecurityqn_pin_input_incorrect",
    MANAGE_SECURITY_QUESTION_FAIL: "managesecurityqn_fail",
    MANAGE_SECURITY_QUESTION_SUCCESS: "managesecurityqn_success",
    MANAGE_SECURITY_QUESTION_PIN_INPUT_CORRECT: "managesecurityqn_pin_input_correct",
    MANAGE_SECURITY_QUESTION_AM_PIN_INPUT_SCREEN_VIEWED: "managesecqn_ampininputscr_viewed",
    MANAGE_SECURITY_QUESTION_SAVE_CLICKED: "managesecurityqn_save_clicked",
    MANAGE_SECURITY_QUESTION_RESPONSE_ADDED: "managesecurityqn_response_added"
}

export const BUY_AIRTIME_EVENTS = {
    BUY_AIRTIME: "buy-airtime",
    PAYMENT_MODE_SELECTED_AM_: "buy_airtime_paymentmodeselected_am_",
    PAYMENT_SUCCESSFUL_SELF: "buy_airtime_paymentsuccess_self",
    PAYMENT_SUCCESSFUL_OTHERS: "buy_airtime_paymentsuccess_others",
    PAYMENT_SUCCESSFUL: "buy_airtime_paymentsuccessful",
    PAYMENT_SUCCESSFUL_AM: "buy_airtime_paymentsuccessful_am",
    GUEST_PAYMENT_SUCCESSFUL_AM_: "guest_buy_airtime_paymentsuccessful_am_",
    TRNX_LIMIT_BREACH: "buy_airtime_rechothers_trxlimitbreach",
    PAYMENT_FAIL_AM_INCORRECT_PIN: "buy_airtime_pymtfail_am_incorrectpin",
    PAYMENT_FAIL_AM_INSUFFICIENT_BALANCE: "buy_airtime_pymtfail_am_insufficientbalance",
    PAYMENT_FAIL_AM_OTHERS: "buy_airtime_pymtfail_am_others",
    PAYMENT_FAIL: "buy_airtime_paymentfailed",
    PAYMENT_FAILED_AM: "buy_airtime_paymentfailed_am",
    GUEST_PAYMENT_FAILED_AM_: "guest_buy_airtime_paymentfailed_am_"
}

export const BUY_BUNDLE_EVENTS = {
    BUY_BUNDLE: "buy-bundle",
    PAYMENT_MODE_SELECTED_AM_: "buy_bndl_paymentmodeselected_am_",
    GUEST_PAYMENT_SUCCESSDUL: "guest_buy_bndl_payment_successful",
    PAYMENT_SUCCESSFUL_AM: "buy_bndl_paymentsuccessful_am",
    GUEST_PAYMENT_SUCCESSFUL_AM: "guest_buy_bndl_paymentsuccessful_am",
    PAYMENT_SUCCESSFUL_AIRTIME: "buy_bndl_paymentsuccessful_airtime",
    GUEST_PAYMENT_SUCCESSFUL_AIRTIME: "guest_buy_bndl_paymentsuccessful_airtime",
    PAYMENT_SUCCESSFUL_MPESA: "buy_bndl_paymentsuccessful_mpesa",
    GUEST_PAYMENT_SUCCESSFUL_MPESA: "guest_buy_bndl_paymentsuccessful_mpesa",
    PAYMENT_SUCCESSFUL: "buy_bndl_paymentsuccessful",
    PAYMENT_SUCCESSFUL_SELF: "buy_bndl_paymentsuccessful_self",
    PAYMENT_SUCCESSFUL_OTHERS: "buy_bndl_paymentsuccessful_others",
    PAYMENT_FAILED: "buy_bndl_paymentfailed",
    GUEST_PAYMENT_FAILED: "guest_buy_bndl_paymentfailed",
    PAYMENT_FAILED_AM: "buy_bndl_paymentfailed_am",
    PAYMENT_FAILED_AIRTIME: "buy_bndl_paymentfailed_airtime",
    PAYMENT_FAILED_MPESA: "buy_bndl_paymentfailed_mpesa",
    GUEST_PAYMENT_FAILED_MPESA: "guest_buy_bndl_paymentfailed_mpesa",
}