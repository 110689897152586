export const LOAN = {
  INITIATE_LOAN_CONFIRMATION_HEADING: "INITIATE_LOAN_CONFIRMATION_HEADING",
  REPAYMENT_AMOUNT: "REPAYMENT_AMOUNT",
  SERIVCE_CHARGE_INCLUSIVE: "SERIVCE_CHARGE_INCLUSIVE",
  LOAN_ELIGIBILITY: "LOAN_ELIGIBILITY",
  REPAY_LOAN: "REPAY_LOAN",
  LOAN_TRANSACTION_HISTORY: "LOAN_TRANSACTION_HISTORY",
  LOAN_OFFERS_FOR_YOU: "LOAN_OFFERS_FOR_YOU",
  AIRTIME_LOAN_ELIGIBILITY: "AIRTIME_LOAN_ELIGIBILITY",
  LOAN_TRANSACTION_SUPPORTS_INFO: "LOAN_TRANSACTION_SUPPORTS_INFO",
  LOAN_DUE: "LOAN_DUE",
  PAY_NOW: "PAY_NOW",
  SERVICE_FEE: "SERVICE_FEE",
  AIRTIME_LOAN: "AIRTIME_LOAN",
  SELECT_AIRTIME_LOAN: "SELECT_AIRTIME_LOAN",
  SELECT_COMBO_LOAN: "SELECT_COMBO_LOAN",
  SELECT_DATA_LOAN: "SELECT_DATA_LOAN",
  SELECT_VOICE_LOAN: "SELECT_VOICE_LOAN",
  AIRTIME_LOAN_REPAYMENT_CAPS: "AIRTIME_LOAN_REPAYMENT_CAPS",
  LOAN_AMOUNT: "LOAN_AMOUNT",
  COMBO_AND_MORE: "COMBO_AND_MORE",
  AIRTIME_LOAN_REPAYMENT: "AIRTIME_LOAN_REPAYMENT",
  AIRTIME_RECHARGE: "AIRTIME_RECHARGE",
  AVAILABLE_CREDIT_LIMIT: "AVAILABLE_CREDIT_LIMIT",
  AIRTIME_LOANS: "AIRTIME_LOANS",
  SELECT_BUY_AIRTIME_LOAN: "SELECT_BUY_AIRTIME_LOAN",
  AIRTIME_LOAN_SUCCESS: "AIRTIME_LOAN_SUCCESS",
  BUNDLE_LOAN_SUCCESS: "BUNDLE_LOAN_SUCCESS",
  REPAY_LOAN_SUCCESS: "REPAY_LOAN_SUCCESS",
  APPLY_LOAN_PAYMENT_METHOD_EXTRA_INFO: "APPLY_LOAN_PAYMENT_METHOD_EXTRA_INFO",
  BUY_AIRTIME_LOAN_AM_SUCCESS: "BUY_AIRTIME_LOAN_AM_SUCCESS",
  AM_PAYMENT_METHOD_EXTRA_INFO: "AM_PAYMENT_METHOD_EXTRA_INFO",
  REPAY_LOAN_MIN_AMOUNT_HINT: "REPAY_LOAN_MIN_AMOUNT_HINT",
  LOAN_REPAYMENT: "LOAN_REPAYMENT",
  LOAN_TAKEN_CARD_TEXT: "LOAN_TAKEN_CARD_TEXT"
};
