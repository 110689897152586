import moment from "moment";
import { headings, UNI } from "../../data/constants";
import { LOAN } from "../../data/loan";
import { addSpaceInMsisdn } from "../../helpers/commonHelpers";
import { getText } from "../../helpers/translateHelper";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux";


const TransactionSummary = ({ data }: any) => {
  const locationData = { ...data };

  return (
    <div className="summary-ctn shadow-[0px_4px_16px_#455B6338] rounded-[5px] mx-10 p-18 mt-12">
      <div className="summary-heading text-[#2C3E50] font-bold text-12">
        {getText("TRANSACTION_SUMMARY")}
      </div>
      <div className="summary-detail-ctn mt-8">
        {!!locationData?.recipientNumber && !locationData?.agentCode &&
          <div className="recipient-number flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText("RECEIPENT_NUMBER")}</div>
            <div className="detail w-1/2 text-14 font-medium text-[#1F2436] break-words text-right">{addSpaceInMsisdn(locationData.recipientNumber)}</div>
          </div>
        }
        {!!locationData?.senderNumber &&
          <div className="recipient-number flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText("SENDER_NUMBER")}</div>
            <div className="detail w-1/2 text-14 font-medium text-[#1F2436] break-words text-right">{addSpaceInMsisdn(locationData.senderNumber)}</div>
          </div>
        }
        {!!locationData?.tillNumber &&
          <div className="recipient-number flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText("TILL_NUMBER")}</div>
            <div className="detail w-1/2 text-14 font-medium text-[#1F2436] break-words text-right">{locationData.tillNumber}</div>
          </div>
        }
        {!!locationData?.phoneNumber &&
          <div className="recipient-number flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText("PHONE_NUMBER")}</div>
            <div className="detail w-1/2 text-14 font-medium text-[#1F2436] break-words text-right">{locationData.phoneNumber}</div>
          </div>
        }
        {!!locationData?.airtimeLoan &&
          <div className="recipient-number flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText(LOAN.AIRTIME_LOAN)}</div>
            <div className="w-1/2 detail text-14 font-medium text-[#1F2436] text-right"><span className="currecy text-[#EC1B24] text-13 font-bold mr-4">{locationData?.currency}</span>{locationData.airtimeLoan}</div>
          </div>
        }
        {!!locationData?.airtimeLoanRepayment &&
          <div className="recipient-number flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText(LOAN.AIRTIME_LOAN_REPAYMENT)}</div>
            <div className="w-1/2 detail text-14 font-medium text-[#1F2436] text-right"><span className="currecy text-[#EC1B24] text-13 font-bold mr-4">{locationData?.currency}</span>{locationData.airtimeLoanRepayment}</div>
          </div>
        }
        {!!locationData?.airtimeRecharge &&
          <div className="recipient-number flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText(LOAN.AIRTIME_RECHARGE)}</div>
            <div className="w-1/2 detail text-14 font-medium text-[#1F2436] text-right"><span className="currecy text-[#EC1B24] text-13 font-bold mr-4">{locationData?.currency}</span>{locationData.airtimeRecharge}</div>
          </div>
        }
        {!!locationData?.serviceFee &&
          <div className="recipient-number flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText(LOAN.SERVICE_FEE)}</div>
            <div className="w-1/2 detail text-14 font-medium text-[#1F2436] text-right"><span className="currecy text-[#EC1B24] text-13 font-bold mr-4">{locationData?.currency}</span>{locationData.serviceFee}</div>
          </div>
        }
        {!!locationData?.name &&
          <div className="recipient-number flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText("NAME")}</div>
            <div className="detail w-1/2 text-14 font-medium text-[#1F2436] text-right truncate">{locationData.name}</div>
          </div>
        }
        {!!locationData?.agentCode &&
          <div className="oprerator-name flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText(headings.AGENT_CODE)}</div>
            <div className="detail w-1/2 text-14 font-medium text-[#1F2436] text-right">{locationData.agentCode}</div>
          </div>
        }
        {!!locationData?.operator &&
          <div className="oprerator-name flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="heading w-1/2 text-13 text-[#1F2436]">{getText(headings.NETWORK_OPERATOR)}</div>
            <div className="detail w-1/2 text-14 font-medium text-[#1F2436] break-words text-right">{locationData.operator}</div>
          </div>
        }
        {!!locationData?.recipientName && !locationData?.agentCode &&
          <div className="recipient-name flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="w-1/2 heading text-13 text-[#1F2436]">{getText("RECEIPENT_NAME")}</div>
            <div className="w-1/2 detail text-14 font-medium text-[#1F2436] break-words text-right">{locationData.recipientName}</div>
          </div>
        }
        {!!locationData?.senderName &&
          <div className="recipient-name flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="w-1/2 heading text-13 text-[#1F2436]">{getText("SENDER_NAME")}</div>
            <div className="w-1/2 detail text-14 font-medium text-[#1F2436] text-right">{locationData.senderName}</div>
          </div>
        }
        {!!locationData?.totalAmount &&
          <div className="total-amount flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="w-1/2 heading text-13 text-[#1F2436]">{getText("TOTAL_AMOUNT")}</div>
            <div className="w-1/2 detail text-14 font-medium text-[#1F2436] text-right"><span className="currecy text-[#EC1B24] text-13 font-bold mr-4">{locationData?.currency}</span>{locationData.totalAmount}</div>
          </div>
        }
        {!!locationData?.repaymentAmount &&
          <div className="total-amount flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="w-1/2 heading text-13 text-[#1F2436]">{getText(LOAN.REPAYMENT_AMOUNT)}</div>
            <div className="w-1/2 detail text-14 font-medium text-[#1F2436] text-right"><span className="currecy text-[#EC1B24] text-13 font-bold mr-4">{locationData?.currency}</span>{locationData.repaymentAmount}</div>
          </div>
        }
        {!!locationData?.units &&
          <div className="total-amount flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="w-1/2 heading text-13 text-[#1F2436]">{getText(headings.VALUE_IN_CURRENCY, UNI)}</div>
            <div className="w-1/2 detail text-14 font-medium text-[#1F2436] text-right"><span className="currecy text-[#EC1B24] text-13 font-bold mr-4"></span>{locationData.units}</div>
          </div>
        }
        {!!locationData?.txnId &&
          <div className="transaction-id flex justify-between items-center py-12 border-b border-b-[#D8D8D8]">
            <div className="w-1/2 heading text-13 text-[#1F2436]">{getText("TRANSACTION_ID")}</div>
            <div className="w-1/2 detail text-14 font-medium  text-[#1F2436] break-words text-right">{locationData.txnId}</div>
          </div>
        }
        {!!locationData?.transactionDateAndTime &&
          <div className="transaction-date flex justify-between items-center pt-12">
            <div className="w-1/2 heading text-13 text-[#1F2436]">{getText("TRANSACTION_DATE")}</div>
            <div className="w-1/2 detail text-14 font-medium text-[#1F2436] text-right">{moment(locationData.transactionDateAndTime).format("DD MMM YYYY")}</div>
          </div>
        }
      </div>
    </div>
  )
};

export default TransactionSummary;