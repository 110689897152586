import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import api from "../../../services/apiService";
import {
  callLaunchConfigApi,
  getUserAttribute,
  getUserAttributeFailed,
  getUserAttributeSucess,
  launchConfigApiFailed,
  launchConfigApiSucess,
} from "../../slices/configSlice";
import { AxiosResponse } from "axios";



function* fetchLaunchConfig(action: PayloadAction<any>): Generator {
  try {
    const response: any = yield api.request({
      method: "GET",
      url: ENDPOINTS.CONFIG.launchConfig,
      hideLoader: action?.payload?.hideLoader
    });
    if (!!response?.data && response?.status === 200) {
      yield put(launchConfigApiSucess(response.data?.result));
      !!action?.payload?.callback && action.payload.callback(response.data?.result?.data?.[0]);
    } else {
      ToastNotifyError(response?.data?.message);
      yield put(launchConfigApiFailed(response));
    }
  }
  catch (error) {
    console.log("Something went wrong", error);
    yield put(launchConfigApiFailed(error));
  }
}

function* fetchUserAttribute(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response = (yield api.request({
      method: "POST",
      url: ENDPOINTS.userAttributes,
      data: payload.data,
      hideLoader: payload?.hideLoader,
    })) as AxiosResponse;

    if (response && response?.data?.statusCode === 200 && !!response?.data?.result?.msisdnCorrelationId) {
      yield put(getUserAttributeSucess(response.data.result.msisdnCorrelationId));
      !!action?.payload?.callback && action.payload.callback(response.data.result.msisdnCorrelationId);
    } else {
      yield put(getUserAttributeFailed(""));
    }
  } catch (error) {
    yield put(getUserAttributeFailed(error));
  }
}

export default function* root(): Generator {
  yield takeLatest(callLaunchConfigApi.type, fetchLaunchConfig);
  yield takeLatest(getUserAttribute.type, fetchUserAttribute);
}
