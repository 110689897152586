import { PayloadAction } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";
import { put } from "redux-saga/effects";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import api from "../../../services/apiService";
import { SOME_ERR, fallbackTranslations } from "../../../data/constants";
import { callFetchLaonTransactions, callFetchLoanProducts, callFetchLoanProductsSucces, callFetchLoanProductsFailed, callApplyLoan } from "../../slices/loanSlice";

function* fetchLoanTransactions(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.LOANS.baseUrl + ENDPOINTS.LOANS.transactionHistory,
      data: payload.data,
      hideLoader: payload?.hideLoader
    });
    if (response?.data?.statusCode === 200) {
      !!action?.payload?.callback && action.payload.callback(response?.data?.result ?? []);
    } else {
      !!action?.payload?.errorCallback && action.payload.errorCallback(response);
      ToastNotifyError(fallbackTranslations[SOME_ERR])
    }
  } catch (error) {
    ToastNotifyError(fallbackTranslations[SOME_ERR])
  }
}



function* fetchLoanProducts(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.LOANS.baseUrl + ENDPOINTS.LOANS.products,
      data: payload.data,
      hideLoader: payload?.hideLoader
    });

    if (response?.data?.statusCode === 200) {
      yield put(callFetchLoanProductsSucces(response?.data));
      action?.payload?.callback?.(response?.data?.result);
    } else {
      yield put(callFetchLoanProductsFailed(response));
      action?.payload?.callback?.(response?.data?.result);
    }
  } catch (error) {
    // console.log(error);
    yield put(callFetchLoanProductsFailed(error));
  }
}

function* applyLoan(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.LOANS.baseUrl + ENDPOINTS.LOANS.applyLoan,
      data: payload.data,
      hideLoader: payload?.hideLoader
    });

    if (response?.status === 200 && response?.data?.statusCode === 200) {
      payload?.successCallback?.(response.data);
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* root(): Generator {
  yield takeLatest(callFetchLaonTransactions.type, fetchLoanTransactions);
  yield takeLatest(callFetchLoanProducts.type, fetchLoanProducts);
  yield takeLatest(callApplyLoan.type, applyLoan);

}