import { useEffect, useState } from "react";
import { getText } from "../../helpers/translateHelper";
import { useDispatch, useSelector } from "react-redux";
import { fetchSecurityQuestions, setSecurityQuestion } from "../../Redux/slices/amSlice";
import { getPhoneNumberFromLs, isSanitizedInput } from "../../utils/commonUtils";
import { RootState } from "../../Redux";
import { securityQuestionsIcn } from "../../config/icons";
import CustomDropdown from "../../components/CustomDropdown";
import SetQuestionSucessPopup from "./Components/SetQuestionSucessPopup";
import { sendGA4Event } from "../../services/ga4";
import { useLocation, useNavigate } from "react-router-dom";
import { AMPinModal } from "../../components/AMPinModal";
import { callAmUserProfile } from "../../helpers/userInfoHelper";
import { ANSWER, SAVE, headings, manageQuestionHeadings } from "../../data/constants";
import { isAMBarred } from "../../helpers/commonHelpers";
import { APP_SETTINGS_ROUTE, HOME_PAGE } from "../../routes/routeConstants";
import { ToastNotifySuccess } from "../../components/ToastNotify";
import { MANAGE_QUESTION_EVENTS, SET_QUESTION_EVENTS } from "../../data/events";

const SetQuestion = () => {
  const securityQuestions = useSelector((state: RootState) => state.amStore.securityQuestions) || [],
    [answer, setAnswer] = useState<string>(""),
    [selectedQuestion, setSelectedQuestion] = useState<any>({}),
    [selectedHeading, setSelectedHeading] = useState<any>(false),
    [showPinPopup, setShowPinpopup] = useState<Boolean>(false),
    [showSuccess, setShowSuccess] = useState<Boolean>(false),
    [errMsg, setErrMsg] = useState<string>(""),
    [pinError, setPinError] = useState<string>(''),
    { ansMinLength, ansMaxLength } = useSelector((state: RootState) => state.config.launchConfig.securityQuestions),

    locData = useLocation()?.state ?? {},
    dispatch = useDispatch(),
    navigate = useNavigate();
  useEffect(() => {
    if (isAMBarred()) {
      navigate(HOME_PAGE, { replace: true });
      return;
    }
    dispatch(fetchSecurityQuestions({}));
    callAmUserProfile();
    if (locData.from === headings.APP_SETTINGS) {
      sendGA4Event({ category: MANAGE_QUESTION_EVENTS.MANAGE_QUESTION, action: MANAGE_QUESTION_EVENTS.MANAGE_SECURITY_QUESTION_SEC_QN_SCREEN_VIEWED });
    }
  }, []);

  function handleAnswerChange(val: string = "") {
    if (isSanitizedInput(val) && validateAns(val)) {
      if (val.length > ansMaxLength) {
        setErrMsg(getText(manageQuestionHeadings.SET_QUESTION_ANSWER_MAX_VALIDATION_ERR));
        return
      } else if (val.length < ansMinLength) {
        setErrMsg(getText(manageQuestionHeadings.SET_QUESTION_ANSWER_MIN_VALIDATION_ERR));
        setAnswer(val);
        return
      }
      setAnswer(val);
      setErrMsg("");
    }
  }

  function validateAns(val: string = "") {
    return val === '' ? true : /^[a-z\d\-_\s]+$/i.test(val);
  }

  function handleSelectedQuestion(data: any = {}) {
    setSelectedQuestion(data);
    setSelectedHeading(true);
    setAnswer("");
    sendGA4Event(locData.from === headings.APP_SETTINGS ? { category: MANAGE_QUESTION_EVENTS.MANAGE_QUESTION, action: MANAGE_QUESTION_EVENTS.MANAGE_SECURITY_QUESTION_QN_SELECTED } : { category: SET_QUESTION_EVENTS.SET_QUESTION, action: SET_QUESTION_EVENTS.SET_SECURITY_QNS_QN_SELECTED });
  }

  function submitAnswer(pin: string = "") {
    setPinError('');
    locData.from === headings.APP_SETTINGS && sendGA4Event({ category: MANAGE_QUESTION_EVENTS.MANAGE_QUESTION, action: MANAGE_QUESTION_EVENTS.MANAGE_SECURITY_QUESTION_PIN_INPUT_DONE });
    let payload = {
      data: {
        msisdn: getPhoneNumberFromLs(),
        pin: pin,
        questionId: selectedQuestion.questionId,
        answer: answer
      },
      callback: function (response: any) {
        if (response.statusCode !== 200) {
          if(response.statusCode == manageQuestionHeadings.SET_QUESTION_PIN_ERROR) {
            setPinError(response.message)
            sendGA4Event({ category: MANAGE_QUESTION_EVENTS.MANAGE_QUESTION, action: MANAGE_QUESTION_EVENTS.MANAGE_SECURITY_QUESTION_PIN_INPUT_INCORRECT })
          } else if(response.statusCode == manageQuestionHeadings.SET_QUESTION_PIN_LOCKED) {
            setPinError(response.message)
            callAmUserProfile();
          } else {
            setErrMsg(response.message)
            togglePinPopup()
          }
          sendGA4Event(locData.from === headings.APP_SETTINGS ? { category: MANAGE_QUESTION_EVENTS.MANAGE_QUESTION, action: MANAGE_QUESTION_EVENTS.MANAGE_SECURITY_QUESTION_FAIL } : { category: SET_QUESTION_EVENTS.SET_QUESTION, action: SET_QUESTION_EVENTS.SET_SECURITY_QN_FAIL });
        } else {
          if (locData.from === headings.APP_SETTINGS) {
            ToastNotifySuccess(getText(manageQuestionHeadings.MANAGE_QUESTION_SUCCESS_TEXT));
            navigate(APP_SETTINGS_ROUTE);
            sendGA4Event({ category: MANAGE_QUESTION_EVENTS.MANAGE_QUESTION, action: MANAGE_QUESTION_EVENTS.MANAGE_SECURITY_QUESTION_SUCCESS });
            sendGA4Event({ category: MANAGE_QUESTION_EVENTS.MANAGE_QUESTION, action: MANAGE_QUESTION_EVENTS.MANAGE_SECURITY_QUESTION_PIN_INPUT_CORRECT });
          } else {
            setShowSuccess(true);
            sendGA4Event({ category: SET_QUESTION_EVENTS.SET_QUESTION, action: SET_QUESTION_EVENTS.SET_SECURITY_QN_SUCCESS });
          }
          callAmUserProfile();
          if (document.activeElement instanceof HTMLElement)
            document?.activeElement?.blur();
        }
      }
    };
    dispatch(setSecurityQuestion(payload));
  }

  function togglePinPopup() {
    if (showPinPopup && (locData.from === headings.APP_SETTINGS)) {
      sendGA4Event({ category: MANAGE_QUESTION_EVENTS.MANAGE_QUESTION, action: MANAGE_QUESTION_EVENTS.MANAGE_SECURITY_QUESTION_AM_PIN_INPUT_SCREEN_VIEWED });
    }
    setShowPinpopup(!showPinPopup);
    pinError !== '' && setPinError('');
  }

  function getDescriptionText() {
    if (locData.from === headings.APP_SETTINGS)
      return getText(manageQuestionHeadings.MANAGE_QUESTION_DESCRIPTION)
    return getText(manageQuestionHeadings.SET_QUESTION_DESCRIPTION)
  }

  function getAmPinText() {
    if (locData.from === headings.APP_SETTINGS)
      return getText(manageQuestionHeadings.MANAGE_QUESTION_PIN_TEXT);
    return getText(manageQuestionHeadings.CONFIRM_PIN_TEXT);
  }

  function handleSave() {

    togglePinPopup();
    sendGA4Event(locData.from === headings.APP_SETTINGS ? { category: MANAGE_QUESTION_EVENTS.MANAGE_QUESTION, action: MANAGE_QUESTION_EVENTS.MANAGE_SECURITY_QUESTION_SAVE_CLICKED } : { category: SET_QUESTION_EVENTS.SET_QUESTION, action: SET_QUESTION_EVENTS.SET_SECURITY_QN_RESPONSE_ADDED });
    sendGA4Event({ category: MANAGE_QUESTION_EVENTS.MANAGE_QUESTION, action: MANAGE_QUESTION_EVENTS.MANAGE_SECURITY_QUESTION_RESPONSE_ADDED });

  }


  return (
    <div className="set-question-overflow">
      <div className="set-question-wrapper flex flex-col items-center">
        <div className="icn-ctn pt-20">
          <img src={securityQuestionsIcn} alt="" />
        </div>
        <div className="decription-ctn text-[#1F2436] text-16 mt-20 text-center max-w-[262px]">
          {getDescriptionText()}
        </div>
        <div className="px-20 w-full">
          <div className="question-dropdown w-full relative mt-32 h-[56px] px-15 pt-[18px] border border-[#DBDBDB] bg-white rounded-[2px]">
            <CustomDropdown
              options={securityQuestions}
              optionKey={"question"}
              onSelect={handleSelectedQuestion}
              selectedOption={selectedQuestion}
              isDisable={false}
              label={getText(headings.SECURITY_QUESTION)}
              heading={getText(manageQuestionHeadings.SELECT_SECURITY_QUESTION)}
              selectedHeading={selectedHeading}
            />
          </div>
          <div className="answer-ctn mt-20 relative">
            {answer && <span className='selected-heading text-12 text-[#000000] font-[300]  capitalize absolute left-15 top-10 opacity-80'>
              {getText(ANSWER)}
            </span>}
            <input className={`w-full h-[56px] px-15  border border-[#DBDBDB] bg-white rounded-[2px] placeholder:text-black ${answer ? 'pt-12' : 'pt-[2px]'} disabled:opacity-50`} type="text" placeholder={getText(ANSWER)} value={answer} onChange={(e) => handleAnswerChange(e.target.value)} onPaste={(e) => { e.preventDefault() }} disabled={!selectedQuestion?.questionId} />
          </div>
          {errMsg && <div className="agent-err text-12 text-red-500 mt-8 font-[500] leading-normal">{errMsg}</div>}
        </div>
        <div className={`w-full bottom-cta fixed bottom-0 left-0 right-0 text-center py-13 text-14 font-bold text-white ${answer && !!selectedQuestion?.questionId && !errMsg ? "bg-[#1F2436]" : "bg-[#b2b4ba] pointer-events-none"}`} onClick={handleSave}>
          {getText(SAVE)}
        </div>
        {showPinPopup ?
          <AMPinModal
            handleSubmit={submitAnswer}
            heading={getAmPinText()}
            closeModal={togglePinPopup}
            msg={pinError}
          />
          :
          null
        }
        {showSuccess ?
          <SetQuestionSucessPopup heading={locData?.heading ?? getText(manageQuestionHeadings.SET_QUESTION_SUCCESS_HEADING)} subHeading={locData?.subHeading ?? getText(manageQuestionHeadings.SET_QUESTION_SUCCESS_DESCRIPTION)} />
          : null
        }
      </div></div>
  )
}

export default SetQuestion;